import { TbConcepto } from '~models/maestros';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { CustomHttpClientService } from 'sumax-erp-schematics';
import { map, mergeMap, shareReplay, toArray } from 'rxjs/operators';
import { Observable } from 'rxjs';

const url = `${environment.HOST_MAESTROS}/tbconceptos`;

@Injectable({
	providedIn: 'root',
})
export class TbConceptoService {
	private _dataSelect$?: Observable<any[]>;

	constructor(protected http: CustomHttpClientService) {}

	findAllByEstadoNgSelect(estado: boolean): Observable<TbConcepto[]> {
		return this.http.get<ApiResponse>(`${url}/model-se-ro/findAllByEstadoNgSelect/${estado}`).pipe(map((res) => res.data as TbConcepto[]));
	}

	getNgSelectSimple(saveInMemory = true): Observable<any[]> {
		if (!saveInMemory) {
			this._dataSelect$ = undefined;
		}
		if (!this._dataSelect$) {
			this._dataSelect$ = this.findAllByEstadoNgSelect(true).pipe(
				mergeMap((data: TbConcepto[]) => {
					return data.map((res) => {
						return { label: `${res.codigo} - ${res.nombre}`, value: res.idConcepto };
					});
				}),
				toArray(),
				shareReplay(1)
			);
		}
		return this._dataSelect$;
	}

	findAllByIdList(ids: number[]): Observable<TbConcepto[]> {
		return this.http.post<ApiResponse<TbConcepto[]>>(`${url}/model-se-ro/findAllByIdList`, ids).pipe(map((res) => res.data));
	}
}
