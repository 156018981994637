import { NgOption } from '@ng-select/ng-select';
import {
	addDays as addDaysFns,
	differenceInDays as differenceInDaysFns,
	endOfMonth as endOfMonthFns,
	format as formatFns,
	formatDistance as formatDistanceFns,
	getMonth as getMonthFns,
	getYear as getYearFns,
	isValid as isValidFns,
	isAfter as isAfterFns,
	parse as parseFns,
	parseISO,
	startOfMonth as startOfMonthFns,
	subDays as subDaysFns,
	lastDayOfMonth,
} from 'date-fns';

export type DateFormat = 'ISO' | 'ISODATE' | 'ISODATETIME_NO_TIMEZONE' | 'ISODATETIME_NO_TIMEZONE_NO_SEC' | 'DATE' | 'ISODATETIME_MAXHOURS' | 'DATETIME' | 'TIME' | 'DATE_HH_MM' | 'DAY' | 'MONTH';

export class DateUtil {
	private static readonly dateRegex = /\d{2}\/\d{2}\/\d{4}/;
	private static readonly timeRegex = /(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])/;
	private static readonly ISO8601 = /^(\d{4}-\d{2}-\d{2})(T(0?[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])(\.\d+)?(Z|[+-]\d{2}:\d{2})?)?$/;
	private static readonly isoNoSec = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;
	private static readonly DATE_FORMATS: Record<DateFormat, string> = {
		ISO: "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
		ISODATE: 'yyyy-MM-dd',
		ISODATETIME_NO_TIMEZONE: "yyyy-MM-dd'T'HH:mm:ss",
		ISODATETIME_NO_TIMEZONE_NO_SEC: "yyyy-MM-dd'T'HH:mm",
		DATE: 'dd/MM/yyyy',
		TIME: 'HH:mm:ss',
		DATETIME: 'dd/MM/yyyy HH:mm:ss',
		ISODATETIME_MAXHOURS: "yyyy-MM-dd'T'23:59:59",
		DATE_HH_MM: 'dd/MM/yyyy HH:mm',
		DAY: 'dd',
		MONTH: 'MM',
	};
	public static MONTHS = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'setiembre', 'octubre', 'noviembre', 'diciembre'];

	static formatDate(date: Date | string | null, format: DateFormat = 'DATE'): any {
		if (date) {
			return formatFns(this.toDate(date), this.DATE_FORMATS[format]);
		} else {
			return '';
		}
	}

	static now(format: DateFormat = 'DATE') {
		return this.format(new Date(), format);
	}

	public static parse(date: string, format: DateFormat): Date {
		return parseFns(date, this.DATE_FORMATS[format], new Date());
	}

	public static format(date: Date, format: DateFormat): string {
		return formatFns(date, this.DATE_FORMATS[format]);
	}

	static isValidDatePickerValue(value: string): boolean {
		return !value || value.length !== 10 ? false : this.isValid(this.parse(value, 'DATE'));
	}

	static isValid(date: string | Date | number): boolean {
		return isValidFns(date);
	}

	static isAfter(date: string | Date, dateToCompare: string | Date): boolean {
		return isAfterFns(this.toDate(date), this.toDate(dateToCompare));
	}

	public static subDays(date: string | Date, days: number): Date {
		const newDate = this.toDate(date);
		return subDaysFns(newDate, days);
	}

	public static addDays(date: string | Date, days: number): Date {
		const newDate = this.toDate(date);
		return addDaysFns(newDate, days);
	}

	static toStrFormat(date: string | Date, targetFormat: DateFormat): string {
		return this.format(this.toDate(date), targetFormat);
	}

	static toDate(date: string | Date): Date {
		if (date instanceof Date) {
			return date;
		}
		const dateFormat = this.getFormat(date);
		let parsedDate = dateFormat == 'ISO' ? parseISO(date) : this.parse(date, dateFormat);
		if (!this.isValid(parsedDate)) {
			throw new Error('Invalid date');
		}
		return parsedDate;
	}

	public static diffDays(later: string | Date, earlier: string | Date): number {
		const laterDate = this.toDate(later);
		const earlierDate = this.toDate(earlier);
		return differenceInDaysFns(laterDate, earlierDate);
	}

	static getYear(date: string | Date = new Date()): number {
		const dateObj = this.toDate(date);
		return getYearFns(dateObj);
	}

	static getMonth(date: string | Date = new Date()): number {
		const dateObj = this.toDate(date);
		return getMonthFns(dateObj);
	}

	static getLastDayOfCurrentMonth(targetFormat: DateFormat = 'DATE'): string {
		return this.formatDate(lastDayOfMonth(new Date()), targetFormat);
	}

	static getLastDayFromMonth(input: string | Date, targetFormat: DateFormat = 'DATE'): string {
		return this.formatDate(lastDayOfMonth(this.toDate(input)), targetFormat);
	}

	static getFirstDayOfCurrentMonth(targetFormat: DateFormat = 'DATE'): string {
		return this.formatDate(startOfMonthFns(new Date()), targetFormat);
	}

	static getBirthDay(input: string | Date) {
		const dia = this.formatDate(input, 'DAY');
		const mes = this.MONTHS[this.formatDate(input, 'MONTH') - 1];
		return `${dia} de ${mes}`;
	}

	static startOfMonth(targetFormat: DateFormat = 'DATE'): string {
		return this.format(startOfMonthFns(new Date()), targetFormat);
	}

	static endOfMonth(fchIni: string | Date = new Date(), targetFormat: DateFormat = 'DATE'): string {
		let date = this.toDate(fchIni);
		return this.format(endOfMonthFns(date), targetFormat);
	}

	static formatDistance(dateStr: string | Date): string {
		return formatDistanceFns(new Date(), this.toDate(dateStr));
	}

	static getFormat(dateStr: string): DateFormat {
		if (this.ISO8601.test(dateStr)) {
			return 'ISO';
		} else if (this.dateRegex.test(dateStr)) {
			return 'DATE';
		} else if (this.timeRegex.test(dateStr)) {
			return 'TIME';
		} else if (this.isoNoSec.test(dateStr)) {
			return 'ISODATETIME_NO_TIMEZONE_NO_SEC';
		} else {
			throw new Error('Unknown format');
		}
	}

	static getNgOptionList(): NgOption[] {
		return this.MONTHS.map((month, index) => ({ value: index, label: month.charAt(0).toUpperCase() + month.slice(1) }));
	}

	isValid(date: string | Date | number): boolean {
		return isValidFns(date);
	}

	static validatorFecha(fch: string): {
		valid: boolean;
		errfch: string;
	} {
		const valid = this.isValid(this.parse(fch, 'DATE'));
		let errfch = '';
		if (!valid) {
			errfch = 'Fecha no valida';
		}
		return { valid, errfch };
	}
}
