import { Injectable } from '@angular/core';

import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';

import { HttpClient } from '@angular/common/http';
import { TbMercanciaPeligrosa } from '~models/maestros';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { ConfigService, GenericService } from 'sumax-erp-schematics';
import { ApiResponse } from '~shared/interface/ApiResponse';

interface MercanciaPeligrosaService {
	findAllByEstadoNgSelect(estado: boolean): Observable<TbMercanciaPeligrosa[]>;
	getSelectList(saveInMemory: boolean): Observable<NgSelectOption<TbMercanciaPeligrosa>[]>;
}

@Injectable({
	providedIn: 'root',
})
export class TbMercanciaPeligrosaService extends GenericService<TbMercanciaPeligrosa, number> implements MercanciaPeligrosaService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbmercanciapeligrosas`;
	private _dataSelect$?: Observable<NgSelectOption<TbMercanciaPeligrosa>[]>;

	constructor(protected readonly _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	// tbClasePeligro?: TbClasePeligro; // no existe
	// estado?: boolean; // no existe
	findAllByEstadoNgSelect(estado = true): Observable<TbMercanciaPeligrosa[]> {
		return this._http.get<ApiResponse<TbMercanciaPeligrosa[]>>(`${this._url}/findAllByEstadoNgSelect/${String(estado)}`).pipe(map((res) => res.data));
	}

	getSelect(item: TbMercanciaPeligrosa | null): NgSelectOption<TbMercanciaPeligrosa> | null {
		return item
			? {
					...item,
					value: item.idMercanciaPeligrosa,
					label: `${item.codigo ?? ''} - ${item.nombre ?? ''}`,
			  }
			: null;
	}

	getSelectList(saveInMemory = true): Observable<NgSelectOption<TbMercanciaPeligrosa>[]> {
		if (!saveInMemory) this._dataSelect$ = undefined;

		if (!this._dataSelect$)
			this._dataSelect$ = this.findAllByEstadoNgSelect(true).pipe(
				map((data) =>
					data.map((item) => ({
						...item,
						value: item.idMercanciaPeligrosa,
						label: `${item.codigo ?? ''} - ${item.nombre ?? ''}`,
					}))
				),
				shareReplay(1)
			);

		return this._dataSelect$;
	}
}
