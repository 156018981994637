import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TbUnidadTemperatura } from '~models/maestros';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

@Injectable({
	providedIn: 'root',
})
export class TbUnidadTemperaturaService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbunidadtemperaturas`;

	constructor(private http: HttpClient) {}

	findById(id: number): Observable<Required<TbUnidadTemperatura>> {
		return this.http.get<ApiResponse<Required<TbUnidadTemperatura>>>(`${this._url}/${id}`).pipe(map((res) => res.data));
	}

	findAllBySearch(): Observable<Required<TbUnidadTemperatura>[]> {
		return this.http.get<ApiResponse<Required<TbUnidadTemperatura>[]>>(`${this._url}/findAllBySearch`).pipe(map((res) => res.data));
	}

	findAllByEstadoNgSelect(estado = true): Observable<Required<TbUnidadTemperatura>[]> {
		return this.http.get<ApiResponse<Required<TbUnidadTemperatura>[]>>(`${this._url}/findAllByEstadoNgSelect/${String(estado)}`).pipe(map((res) => res.data));
	}

	getSelectList(): Observable<NgSelectOption<TbUnidadTemperatura>[]> {
		return this.findAllByEstadoNgSelect().pipe(
			map((data) => {
				return data.map((item) => {
					return {
						...item,
						value: item.idUnidadTemperatura,
						label: `${item.codigo} - ${item.nombre}`,
					};
				});
			})
		);
	}

	/**
	 * @deprecated no utilizar
	 */
	getSelect(item: TbUnidadTemperatura | null): NgSelectOption<TbUnidadTemperatura> | null {
		return item
			? {
					...item,
					value: item.idUnidadTemperatura,
					label: `${item.codigo ?? ''} - ${item.nombre ?? ''}`,
			  }
			: null;
	}
}
