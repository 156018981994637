import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map } from 'rxjs';
import { TbConcepto, TbGrupoConceptoConcepto } from '~models/maestros';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

@Injectable({
	providedIn: 'root',
})
export class TbGrupoConceptoConceptoService {
	private readonly _url = `${environment.HOST_MAESTROS}/tbgrupoconceptoconceptos`;

	constructor(private readonly _http: HttpClient) {}

	findAllByIdGrupoConcepto(idGrupoConcepto: number): Observable<NgSelectOption<TbGrupoConceptoConcepto>[]> {
		return this._http.get<ApiResponse<TbGrupoConceptoConcepto[]>>(`${this._url}/model-se-ro/findAllByIdGrupoConcepto/${idGrupoConcepto}`).pipe(
			map((resp) =>
				resp.data.map((item) => ({
					...item,
					value: item.tbConcepto?.idConcepto,
					label: `${item.tbConcepto?.codigo} - ${item.tbConcepto?.nombre}`,
				}))
			)
		);
	}

	getSelect(item: TbConcepto | null | undefined): NgSelectOption<TbConcepto> | null {
		return item
			? {
					...item,
					value: item.idConcepto,
					label: `${item.codigo} - ${item.nombre}`,
			  }
			: null;
	}
}
