import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { TbMercanciaPeligrosaReguladorVia, TbReguladorMercanciaPeligrosa } from '~models/maestros';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';
import { ConfigService, GenericService } from 'sumax-erp-schematics';
import { ApiResponse } from '~shared/interface/ApiResponse';

interface MercanciaPeligrosaReguladorViaService {
	findAllByCodigoMercanciaAndCodigoViaNgSelect(codigoMercancia: string, codigoVia: string, estado: boolean): Observable<TbReguladorMercanciaPeligrosa[]>;
	getSelectList(codigoMercanciaPeligrosa: string, codigoViaTransporte: string, saveInMemory: boolean): Observable<NgSelectOption<TbReguladorMercanciaPeligrosa>[]>;
}

@Injectable({
	providedIn: 'root',
})
export class TbMercanciaPeligrosaReguladorViaService extends GenericService<TbReguladorMercanciaPeligrosa, number> implements MercanciaPeligrosaReguladorViaService {
	private readonly _url = `${environment.HOST_MAESTROS_SYNC}/tbmercanciapeligrosareguladorvias`;

	private _dataSelect$?: Observable<NgSelectOption<TbReguladorMercanciaPeligrosa>[]>;

	constructor(protected readonly _http: HttpClient) {
		super(_http);
	}

	get configuration(): ConfigService<number> {
		return {
			url: {
				base: this._url,
			},
			getPK(pk: number): string {
				return `${pk}`;
			},
		};
	}

	findAllByCodigoMercanciaAndCodigoViaNgSelect(codigoMercancia: string, codigoVia: string): Observable<TbReguladorMercanciaPeligrosa[]> {
		return this._http.get<ApiResponse<TbMercanciaPeligrosaReguladorVia[]>>(`${this._url}/findAllByCodigoMercanciaAndCodigoViaNgSelect/${codigoMercancia}/${codigoVia}`).pipe(
			map((res) => res.data),
			map((data) => data.map((d) => d.tbReguladorMercanciaPeligrosa))
		);
	}

	getSelect(item: TbReguladorMercanciaPeligrosa | null): NgSelectOption<TbReguladorMercanciaPeligrosa> | null {
		return item
			? {
					...item,
					value: item.idReguladorMercanciaPeligrosa,
					label: `${item.codigo ?? ''} - ${item.nombre ?? ''}`,
			  }
			: null;
	}

	getSelectList(codigoMercancia: string, codigoVia: string): Observable<NgSelectOption<TbReguladorMercanciaPeligrosa>[]> {
		this._dataSelect$ = this.findAllByCodigoMercanciaAndCodigoViaNgSelect(codigoMercancia, codigoVia).pipe(
			map((data) =>
				data.map((item) => ({
					...item,
					value: item.idReguladorMercanciaPeligrosa,
					label: `${item.codigo ?? ''} - ${item.nombre ?? ''}`,
				}))
			),
			shareReplay(1)
		);

		return this._dataSelect$;
	}
}
