import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, map, shareReplay } from 'rxjs';
import { TbEntidad, TbProveedor } from '~models/maestros';
import { ApiResponse } from '~shared/interface/ApiResponse';
import { NgSelectOption } from '~shared/interface/ngselect.interfaces';

@Injectable({
	providedIn: 'root',
})
export class TbProveedorService {
	private readonly _url = `${environment.HOST_MAESTROS}/tbproveedores`;

	constructor(private readonly _http: HttpClient) {}

	findAllByEstadoNgSelect(estado = true): Observable<TbProveedor[]> {
		return this._http.get<ApiResponse<TbProveedor[]>>(`${this._url}/model-se-ro/findAllByEstadoNgSelect/${estado}`).pipe(map((res) => res.data));
	}

	getSelectListTbEntidadProveedor(): Observable<NgSelectOption<TbEntidad>[]> {
		return this.findAllByEstadoNgSelect().pipe(
			map((data) =>
				data.map((item: TbProveedor) => ({
					...item.tbEntidad,
					value: item.tbEntidad?.idEntidad,
					label: `${item.tbEntidad?.nroDocumento} - ${item.tbEntidad?.razonSocial}`,
				}))
			),
			shareReplay(1)
		);
	}

	getSelect(item: TbEntidad | null | undefined): NgSelectOption<TbEntidad> | null {
		return item
			? {
					...item,
					value: item.idEntidad,
					label: `${item.nroDocumento} - ${item.razonSocial}`,
			  }
			: null;
	}
}
